import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'NVT',
    tokenAddress: '0xE4f064cAF3e31238Ce9620672D15e14ab989d821',   // token address
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0xE4f064cAF3e31238Ce9620672D15e14ab989d821',  // token address
    contractAddress: {
      97: '',
      56: '0x0F8E7311f5a24b94310232a32e40E5a2B684b76b',  /// masterchef address
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'http://novatoken.eu/  ',
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
 
]

export default pools
