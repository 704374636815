import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'NVT',
    lpAddresses: {
      97: '',
      56: '0xE4f064cAF3e31238Ce9620672D15e14ab989d821',
    },
    tokenSymbol: 'SYRUP',
    tokenAddresses: {
      97: '',
      56: '0xE4f064cAF3e31238Ce9620672D15e14ab989d821',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 1,
    lpSymbol: 'NVT-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x2d68DCd05a4917c725454384a364f93D27E97568',   // lp address token-bnb
    },
    tokenSymbol: 'NVT',
    tokenAddresses: {
      97: '',
      56: '0xE4f064cAF3e31238Ce9620672D15e14ab989d821', // token address
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'NVT-USDT LP',
    lpAddresses: {
      97: '',
      56: '0x9cb59869f9184Ee434f52B92ed1Be2c9eC69e7AD',   // lp address token-bnb
    },
    tokenSymbol: 'NVT',
    tokenAddresses: {
      97: '',
      56: '0xE4f064cAF3e31238Ce9620672D15e14ab989d821', // token address
    },
    quoteTokenSymbol: QuoteToken.UST,
    quoteTokenAdresses: contracts.ust,
  },
  

]

export default farms
